.signInForm {
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 3% 7%;
  width: 100%;
  border-radius: 10px;
  color: #00242e;
}
.signInForm label {
  color: #00242e;
}
.vsc-initialized modal-open {
  border: 3px solid red;
}

.signInForm span {
  color: #a97900;
}

.signInForm button {
  background-color: #a97900 !important;
  font: normal normal normal 17px/17px SF Pro Text !important;
  padding: 10px 0;
}
