.lawyer-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 600px;

}

 

.lawyer-container {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    flex-basis: 300px;
}

.lawyer-branch-container {

    border: 1px solid #A97900;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 330px;
    min-height: 500px;
    max-width: 100%;
}

.branch-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.carousel,
.carousel-inner {
    min-width: 1100px;
}

.carousel-control-next {
    position: relative;

    transform: translateX(50%);
    z-index: 1;

}

.carousel-control-prev {
    position: relative;

    transform: translateX(-50%);
    z-index: 1;

}

@media only screen and (max-width: 576px) {
    .lawyer-branch-container {
        flex-basis: 100%;
        margin: 10px 0;
    }
}

