.nasilCalisir {
  margin: auto;
  top: 766px;
  left: 385px;
  width: 1151px;
  height: 819px;
  padding-top: 2.5rem;
  color: #00242e;
  opacity: 1;
}
.nasilCalisirContainer {
  display: flex;
  margin-top: 5rem;
  position: relative;
}
@media screen and (max-width: 500px) {
  .nasilCalisirContainer {
    display: block;
    margin-top: 5rem;
    position: static;
  }
  .nasilCalisirContainer svg {
    position: static;
    margin-bottom: -150%;
  }
}
.nasilCalisirBaslik {
  top: 766px;
  left: 695px;
  width: 530px;
  height: 45px;
  font-weight: bold;
  color: #00242e;
  font-size: 38px;
  font-family: SF Pro Text;
  margin: auto;
}
.nasilCalisirFirstDiv {
  top: 861px;
  left: 385px;
  width: 269px;
  height: 602px;
  margin: auto;
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #a97900;
}
.nasilCalisirFirstDivP {
  top: 861px;
  left: 411px;
  width: 218px;
  height: 219px;
  margin: auto;
  margin-top: -2rem;
  background: #a97900 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.nasilCalisirFirstDiv p {
  top: 886px;
  left: 496px;
  width: 48px;
  height: 90px;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: #ffffff;
  font: normal normal normal 81px/107px Algerian;
}
.nasilCalisirSecondDiv p {
  top: 886px;
  left: 496px;
  width: 48px;
  height: 90px;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: #ffffff;
  font: normal normal normal 81px/107px Algerian;
}
.nasilCalisirMiddlePart h1 {
  background-color: var(--main-color);
  border-radius: 50%;
  padding: 3rem;
  margin-top: -1rem;
  min-width: 115px;
}
.nasilCalisirContent {
  border: 1px solid rgb(116, 97, 97);
  border-radius: 7px;
  width: 15%;
  min-width: 160px;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
}
.nasilCalisirh1 {
  top: 1032px;
  left: 473px;
  width: 238px !important;
  height: 80%;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 5px 10px #0000004d;
  text-align: center;
  letter-spacing: 0px;
  font-size: 1rem !important;
  color: black !important;
  background-color: white;
  margin: auto;
  margin-top: -10rem;
  line-height: 1rem !important;
  padding: 0 1rem;
}

.nasilCalisirsvg1 {
  position: absolute;
  bottom: 8%;
  left: 14%;
  margin: 5px;
}

.nasilCalisirsvg2 {
  position: absolute;
  bottom: 8%;
  left: 47%;
}
.nasilCalisirsvg3 {
  position: absolute;
  bottom: 6%;
  left: 82%;
}
.ucgen {
  width: 0;
  height: 0;

  margin: auto;
  border-top: 40px solid #a97900;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  margin-top: -9px !important;
}
.ucgen2 {
  width: 0;
  height: 0;
  margin: auto;
  border-top: 40px solid #00242e;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  margin-top: -9px !important;
}
